import React from 'react';
import SectionHeading from '../components/atom/SectionHeading';
import styled from 'styled-components';
import Spacer from '../components/Misc/Spacer';

// * entire section + optional spacer
const Root = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  /* border: 1px solid red; */
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px;
  background-color: ${(props) => props.theme.color.background};
`;

const Section = ({ title, subtitle, spacer, children, refType }) => {
  return (
    // having a Root allows the <Spacer />, if it's present, to go full width while the SectionWrapper which is the container main for content can have it's 25 15 padding.
    <Root ref={refType}>
      <SectionWrapper>
        <SectionHeading title={title} subtitle={subtitle} />
        {children}
      </SectionWrapper>
      {spacer && <Spacer />}
    </Root>
  );
};

export default Section;
