import React from 'react';
import styled from 'styled-components';

const StyledSectionHeading = styled.h2`
  /* border: 1px solid peru; */
  text-align: center;
  /* background: peru; */
  letter-spacing: 5px;
  font-weight: 500;
`;

const StyledSectionHr = styled.div`
  width: 56px;
  /* height: 1px; */
  background-color: #000;
  border: 3px solid #000;
  border-width: 3px;
  margin: 10px auto 20px;
`;

const Root = styled.div`
  width: 100%;
`;

const Subtitle = styled.p`
  /* border: 1px solid red; */
  text-align: center;
  margin: 10px 0 20px;
  font-style: italic;
  font-size: 106px; //!
`;

const SectionHeading = ({ title, subtitle }) => {
  return (
    <Root>
      <StyledSectionHeading>{title}</StyledSectionHeading>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <StyledSectionHr />
    </Root>
  );
};

export default SectionHeading;
