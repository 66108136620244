import React from 'react';
import express from '../../../assets/Technologies/Orange/express-ORANGE.svg';
import knex from '../../../assets/Technologies/Orange/Knex-ORANGE.svg';
import sqlite from '../../../assets/Technologies/Orange/SQLite3-ORANGE.svg';
import { SiNodeDotJs } from 'react-icons/si';

import { StyledCardRoot, StackCardHeading, Section, Span, TechName } from './StyledCard';

const Backendcard = () => {
  return (
    <StyledCardRoot>
      <StackCardHeading>Backend:</StackCardHeading>
      <Section>
        <Span>
          <SiNodeDotJs />
          <TechName>NodeJS</TechName>
        </Span>
        <Span>
          <img src={express} alt="Express logo" />
          <TechName>Express</TechName>
        </Span>
        <Span>
          <img src={knex} alt="Knex logo" />
          <TechName>Knex</TechName>
        </Span>
        <Span>
          <img src={sqlite} alt="SQLite logo" />
          <TechName>SQLite</TechName>
        </Span>
      </Section>
    </StyledCardRoot>
  );
};

export default Backendcard;
