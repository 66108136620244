import React from 'react';
import Backendcard from './cards/BackendCard';
import FrontendCard from './cards/FrontendCard';
import Tools from './cards/ToolsCard';
import StyledStack from './StyledStack';

const Stack = (props) => {
  return (
    <StyledStack>
      <FrontendCard />
      <Backendcard />
      <Tools />
    </StyledStack>
  );
};

export default Stack;
