import styled from 'styled-components';

const StyledStack = styled.div`
  /* border: 2px dotted black; */

  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export default StyledStack;
