import React, { useState } from 'react';
import song from '../assets/audio/All_dressed_in_blue.mp3';
import styled from 'styled-components';

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
`;
const LyricContainer = styled.ul``;
const Line = styled.li`
  font-size: 3rem;
`;
const Song = () => {
  const [audio, setAudio] = useState(new Audio(song));
  const [isPlaying, setIsPlaying] = useState(false);

  const playPause = () => {
    // let isPlaying = isPlaying;
    isPlaying ? audio.pause() : audio.play();
    setIsPlaying(!isPlaying);
  };

  return (
    <Page>
      <button
        style={{ fontSize: '2rem', padding: '1rem 3rem' }}
        onClick={playPause}
      >
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      {/* <LyricContainer>
        <Line>All dressed in blue stands there perfectly you</Line>
        <Line>On the first day that feels like it’s properly spring</Line>
        <Line>And I think to myself, I’m in love</Line>
        <br />
        <Line>Heart in my throat as I slowly approach</Line>
        <Line>The night air helps me breathe despite buckling knees</Line>
        <Line>As I brace to say, hello</Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
        <Line></Line>
      </LyricContainer> */}
    </Page>
  );
};

export default Song;

// class App extends Component {
//   state = {
//     audio: new Audio(song),
//     isPlaying: false,
//   };

//   playPause = () => {
//     // Get state of song
//     let isPlaying = this.state.isPlaying;
//     isPlaying ? this.state.audio.pause() : this.state.audio.play();
//     this.setState({ isPlaying: !isPlaying });
//   };

//   render() {
//     return (
//       <div style={{ padding: '3rem' }}>
//         <button onClick={this.playPause}>
//           {this.state.isPlaying ? 'Pause' : 'Play'}
//         </button>
//       </div>
//     );
//   }
// }

// export default App;
