import React from 'react';
import { ShortRoot, Question, Answer, Link } from './StyledShort';
const Short = (props) => {
  return (
    <ShortRoot>
      <Question>What are you doing now?</Question>
      <Answer>
        Teaching next generation developers at{' '}
        <Link
          href="https://www.fullstackacademy.com/"
          target="_blank"
          rel="noreferrer"
        >
          FullStack Academy
        </Link>
        .
      </Answer>
      <Question>Why tech?</Question>
      <Answer>
        Teamwork, constant learning, making things, solving problems.
      </Answer>
      <Question>So, what's next?</Question>
      <Answer>Writing better tests and speaking at conferences.</Answer>
      <Question>What's important to you in a job?</Question>
      <Answer>Good people, product, and process + remote compatible.</Answer>
      <Question>Aside from programming, what else do you enjoy?</Question>
      <Answer>Theater, travel, tea, alliteration, and Muji pens.</Answer>
      <Question>Any travel in particular on your mind?</Question>
      <Answer>
        Thank you for asking! Fantasizing about{' '}
        <Link
          href="https://cycletours.com/cycling-holidays/countries/germany/danube-waltz-guided/"
          target="_blank"
          rel="noreferrer"
        >
          this...
        </Link>
      </Answer>
      <Question>Your background is in theater?!</Question>
      <Answer>You think closures are heady? Try Shakespeare.</Answer>
    </ShortRoot>
  );
};

export default Short;
