import React from 'react';
import { SiJavascript, SiRedux, SiReact, SiStyledComponents, SiGatsby } from 'react-icons/si';
import { StyledCardRoot, StackCardHeading, Section, Span, TechName } from './StyledCard';

const FrontendCard = () => {
  return (
    <StyledCardRoot>
      <StackCardHeading>Frontend:</StackCardHeading>
      <Section>
        <Span>
          <SiReact />
          <TechName>React</TechName>
        </Span>
        <Span>
          <SiGatsby />
          <TechName>Gatsby</TechName>
        </Span>
        <Span>
          <SiJavascript />
          <TechName>JavaScript</TechName>
        </Span>
        <Span>
          <SiRedux />
          <TechName>Redux</TechName>
        </Span>
        {/* Redux Saga */}
        <Span>
          <SiStyledComponents />
          <TechName>Styled-Components</TechName>
        </Span>
      </Section>
    </StyledCardRoot>
  );
};

export default FrontendCard;
