import React from 'react';
import { StyledCardRoot, StackCardHeading, Section, Span, TechName } from './StyledCard';
import { SiFigma, SiGit, SiGithub } from 'react-icons/si';

const Tools = () => {
  return (
    <StyledCardRoot>
      <StackCardHeading>Industry Tools:</StackCardHeading>
      <Section>
        <Span>
          <SiFigma />
          <TechName>Figma</TechName>
        </Span>
        <Span>
          <SiGit />
          <TechName>Git</TechName>
        </Span>
        <Span>
          <SiGithub />
          <TechName>GitHub</TechName>
        </Span>
      </Section>
    </StyledCardRoot>
  );
};

export default Tools;
