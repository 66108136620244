import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './themes/theme';
import Song from './components/song';

// import darkTheme from "./themes/darktheme";
// const colorTheme = JSON.parse(localStorage.getItem("colorTheme"));
// let themeInUse = colorTheme === "dark" ? darkTheme : theme;
let themeInUse = theme;

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/song',
    element: <Song />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeInUse}>
      <RouterProvider router={router} />{' '}
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
