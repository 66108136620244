import styled from 'styled-components';

export const ShortRoot = styled.ul`
  /* border: 1px solid red; */
  * {
    /* border: 1px solid black; */
  }
  padding: 10px;
  background-color: ${(props) => props.theme.color.white};
  /* max-width: 500px; */
  box-shadow: -32px 29px 110px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

export const Question = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
`;

export const Answer = styled.li`
  /* color: red; */
  padding-left: 16px;
  margin: 20px 0;
  font-style: italic;
  text-align: right;
  font-size: 2.2rem;
  letter-spacing: 1px;
  line-height: 2.6rem;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.color.orange};
  font-size: 2.2rem;
`;
