import styled from 'styled-components';

export const StyledCardRoot = styled.div`
  /* border: 1px solid peru; */

  background-color: ${(props) => props.theme.color.background};
  box-shadow: -9px 15px 40px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 10px;
  width: 300px;
  margin: 0 10px 10px 10px;
`;

export const StackCardHeading = styled.h4`
  margin-bottom: 5px 0 10px;
  text-align: center;
`;

export const Section = styled.section`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-template-rows: auto;
`;

export const Span = styled.span`
  /* border: 1px solid red; */

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1px;
  /* justify-content: center; */
  svg {
    /* border: 1px solid blue; */

    margin: 10px;
    height: 50px;
    width: 50px;
  }
`;

export const TechName = styled.p`
  /* border: 1px solid red; */

  text-align: center;
`;
